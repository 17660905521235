import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faCalendar,
  faCopy,
  faMapMarkerAlt,
  faMars,
  faRunning,
  faVenus,
} from '@fortawesome/free-solid-svg-icons';
import { Transition } from '@headlessui/react';
import DetailIemLoader from 'components/DetailItemLoader';
import { useUserProfile } from 'profiles/queries/profileHooks';
import { useParams } from 'react-router-dom';
import Verified from '../../assets/img/Vectorverified.svg';

export default function SideBarProfile() {
  const getProfileData = useUserProfile();
  const [showTooltip, setShowTooltip] = useState(false);
  const params = useParams();
  const [expand, setExpand] = useState(false);
  return (
    <DetailIemLoader queryData={getProfileData} queryKey="id">
      {({ data }) => {
        return (
          <div className="lg:mx-0 bg-gray-125 md:mx-4 md:block lg:w-72 md:border-1 md:border-gray-250 md:bg-white ms:rounded-md">
            <div className="hidden md:flex flex-col items-center py-6 shadow-shadowBottom">
              <div
                data-cy="user_image"
                className="w-32 h-32 rounded-full overflow-hidden inline-block"
                style={{
                  background: `url('${data?.message.image}')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <p data-cy="full_name" className="text-2xl font-bold mt-3 px-2 text-center">
                {data?.message?.full_name}
              </p>
              <button
                className="flex gap-3 px-2 py-1 items-center bg-blue-50 mt-1"
                onClick={(e) => {
                  navigator.clipboard.writeText(data?.message?.sfa_id);
                  setShowTooltip(true);
                  e.target.classList.add('animate-button');
                  setTimeout(() => {
                    e.target.classList.remove('animate-button');
                    setShowTooltip(false);
                  }, 3000);
                }}
              >
                <p data-cy="sfa_id" className="text-blue-650 text-sm">
                  {data?.message?.sfa_id}
                </p>
                <FontAwesomeIcon icon={faCopy} className="text-blue-650 text-sm" />
              </button>
              <Transition
                show={showTooltip}
                as={Fragment}
                enter="transition ease-out duration-500"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-500"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div className={`mt-2 px-4 py-1 rounded-md text-white font-xxs bg-gray-750`}>
                  Copied!
                </div>
              </Transition>
            </div>
            {/* Mobile View */}
            <div className="flex flex-row justify-start md:hidden gap-2 items-center py-6 px-4">
              <img
                className="w-12 h-12 rounded-full overflow-hidden inline-block object-cover"
                src={data?.message?.image}
                // style={{
                //   background: `url('${data?.message.image}')`,
                //   backgroundSize: 'cover',
                //   backgroundRepeat: 'no-repeat',
                //   backgroundPosition: 'center',
                // }}
                alt={''}
              />

              <div className="flex flex-col items-start text-center flex-grow">
                <div className={'flex justify-start w-full mb-1 flex-wrap'}>
                  <div className={'flex items-center justify-start'}>
                    <p className="text-lg font-bold px-0 text-center truncate">
                      {data?.message?.full_name || '-'}
                    </p>

                    <img src={Verified} className={'ml-2 w-4 h-4 mr-2'} alt={''} />
                  </div>
                  <button
                    className="flex gap-3 px-2 py-1 items-center bg-blue-50"
                    onClick={(e) => {
                      navigator.clipboard.writeText(data?.message?.sfa_id);
                      setShowTooltip(true);
                      e.target.classList.add('animate-button');
                      setTimeout(() => {
                        e.target.classList.remove('animate-button');
                        setShowTooltip(false);
                      }, 3000);
                    }}
                  >
                    <p className="text-blue-650 text-xxs truncate">{data?.message?.sfa_id}</p>
                    <FontAwesomeIcon icon={faCopy} className="text-blue-650 text-xs" />
                  </button>
                  <Transition
                    show={showTooltip}
                    as={Fragment}
                    enter="transition ease-out duration-500"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-500"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div
                      className={`mt-2 px-4 py-1 rounded-md text-white font-xxs bg-gray-750 absolute`}
                    >
                      Copied!
                    </div>
                  </Transition>
                </div>
                {data?.message?.primary_role === 'institution' ? (
                  ''
                ) : (
                  <div className={'flex text-xs justify-start text-left w-full flex-wrap'}>
                    <span style={{ color: '#606060' }}>Age : </span>
                    {data?.message?.age} Years &nbsp;
                    <span style={{ color: '#606060' }}>&bull;</span>&nbsp;
                    {/* <span style={{ color: '#606060' }}>Gender :</span>
                    {data?.message?.gender} &nbsp;<span style={{ color: '#606060' }}>&bull;</span>
                    &nbsp; */}
                    <span style={{ color: '#606060' }}>Institute : </span>
                    {data?.message?.school_name === '' ? '-' : data?.message?.school_name}
                  </div>
                )}
              </div>
            </div>
            {data?.message.primary_role === 'institution' ? (
              ''
            ) : (
              <div className="pt-2.5 pb-6 px-5 hidden md:flex lg:flex-col gap-6">
                <div className="flex items-center gap-3 text-sm">
                  <FontAwesomeIcon icon={faCalendar} className="text-gray-450 w-4 h-4" />
                  <p data-cy="age" className="text-gray-700 font-medium">
                    {data?.message?.age} years
                  </p>
                </div>
                <div className="flex items-center gap-3 text-sm">
                  <FontAwesomeIcon
                    icon={data?.message?.gender === 'Male' ? faMars : faVenus}
                    className="text-gray-450 w-4 h-4"
                  />
                  <p data-cy="gender" className="text-gray-700 font-medium">
                    {data?.message?.gender}
                  </p>
                </div>
                <div className="flex items-center gap-3 text-sm">
                  <FontAwesomeIcon icon={faBuilding} className="text-gray-450 w-4 h-4" />
                  <p data-cy="school_name" className="text-gray-700 font-medium">
                    {data?.message?.school_name === '' ? '-' : data?.message?.school_name}
                  </p>
                </div>
                <div className="flex items-center gap-3 text-sm">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-450 w-4 h-4" />
                  <p className="text-gray-700 font-medium">
                    {data?.message?.city === '' ? '-' : data?.message?.city}
                  </p>
                </div>
                {params.user !== 'coach' ? (
                  <div className="flex items-center gap-3 text-sm">
                    <FontAwesomeIcon icon={faRunning} className="text-gray-450 w-4 h-4" />
                    <p
                      data-cy="participated_sports"
                      className="text-gray-700 text-sm font-medium overflow-auto break-words"
                    >
                      {data?.message?.participated_sports === '' ||
                      data?.message?.participated_sports?.length === 0 ? (
                        '-'
                      ) : (
                        <>
                          {!expand
                            ? data?.message?.participated_sports?.slice(0, 3).join(' , ')
                            : data?.message?.participated_sports.join(' , ')}{' '}
                          {data?.message?.participated_sports?.length > 3 ? (
                            <span className={'cursor-pointer'} onClick={() => setExpand(!expand)}>
                              {' '}
                              ...
                            </span>
                          ) : null}
                        </>
                      )}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}

            {/* Mobile View */}
            {/*
            {data?.message.primary_role === 'institution' ? (
              ''
            ) : (
              <div className="pt-2.5 pb-6 px-5 md:flex md:flex-row lg:hidden flex justify-around md:justify-around gap-6 md:gap-0">
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-3">
                    <FontAwesomeIcon icon={faCalendar} className="text-sm text-gray-450 w-4 h-4" />
                    <p data-cy="age" className="text-gray-700 text-sm font-medium">
                      {data?.message?.age} years
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <FontAwesomeIcon
                      icon={data?.message?.gender === 'Male' ? faMars : faVenus}
                      className="text-gray-450 w-4 h-4"
                    />
                    <p data-cy="gender" className="text-gray-700 text-sm font-medium">
                      {data?.message?.gender}
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <FontAwesomeIcon icon={faBuilding} className="text-sm text-gray-450 w-4 h-4" />
                    <p data-cy="school_name" className="text-gray-700 text-sm font-medium">
                      {data?.message?.school_name === '' ? '-' : data?.message?.school_name}
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-sm text-gray-450 w-4 h-4"
                    />
                    <p className="text-gray-700 text-sm font-medium">
                      {data?.message?.city === '' ? '-' : data?.message?.city}
                    </p>
                  </div>
                  {params.user !== 'coach' ? (
                    <div className="flex items-center gap-3">
                      <FontAwesomeIcon icon={faRunning} className="text-sm text-gray-450 w-4 h-4" />
                      <p
                        data-cy="participated_sports"
                        className="text-gray-700 text-sm font-medium overflow-auto break-words"
                      >
                        {data?.message?.participated_sports === '' ||
                        data?.message?.participated_sports?.length === 0 ? (
                          '-'
                        ) : (
                          <>
                            {!expand
                              ? data?.message?.participated_sports?.slice(0, 3).join(' , ')
                              : data?.message?.participated_sports.join(' , ')}{' '}
                            {data?.message?.participated_sports?.length > 3 ? (
                              <span className={'cursor-pointer'} onClick={() => setExpand(!expand)}>
                                {' '}
                                ...
                              </span>
                            ) : null}
                          </>
                        )}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
*/}
          </div>
        );
      }}
    </DetailIemLoader>
  );
}
