import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SideBarProfile from './Components/SideBarProfile.js';
// import Notifications from './Components/Notifications';
import Loader from 'components/Loader';

// Lazy imports
const Tournaments = lazy(() => import('./Tournaments'));
const TournamentsList = lazy(() => import('./user/TournamentsList'));
const ProfileStatistics = lazy(() => import('./user/ProfileStatistics'));
const Gallery = lazy(() => import('./user/GalleryView'));

export default function Profile() {
  const match = useRouteMatch('/:user/profiles');
  // const user = useParams().user;

  return (
    <Suspense fallback={<Loader />}>
      {/*
      <ProfileBar
        head="Home"
        redirectTo={`/${user}/profiles/profile`}
        isDark
        className={`${window.location.pathname.split('/').at(-1) !== 'profile' && 'hidden'}`}
      />
*/}
      <div className="bg-gray-100 lg:flex gap-4 justify-center md:my-6 mx-0 md:mx-4 xl:mx-9 text-roboto">
        <div
          className={`flex flex-col gap-4 ${
            window.location.pathname.split('/').at(-1) !== 'profile' && 'hidden md:flex'
          }`}
        >
          <SideBarProfile />
          {/* <div data-cy="sidebar_notification" className="hidden lg:block">
            <Notifications />
          </div> */}
        </div>
        <div className="w-full lg:mt-0">
          {/* <Tabs tabList={ProfileTabs} /> */}
          <Switch>
            <Route exact path={`${match.url}`}>
              <Redirect to={`${match.url}/profile`} />
            </Route>
            <Route path={`${match.url}/profile`} component={TournamentsList} />
            <Route path={`${match.url}/tournaments/:id`} component={Tournaments} />
            <Route path={`${match.url}/tournaments/statistics`} component={ProfileStatistics} />
            <Route path={`${match.url}/tournaments/gallery`} component={Gallery} />
          </Switch>
        </div>
      </div>
    </Suspense>
  );
}
